<template>
    <div class="achievement">
        <div class="header">
            <div class="service-banner">
                <div class="news-tips">
                    <div>成果展示</div>
                    <div class="news-tips-en">ACHIEVEMENT DISPLAY</div>
                </div>
            </div>
        </div>
        <div class="itemBox">
            <div class="breadcrumb">
                <span class="search-box" :class="{ select: queryData.Type == 0 ? true : false }" @click="handleSort(0)">科研试验项目成果</span>
                <span class="search-box" :class="{ select: queryData.Type == 1 ? true : false }" @click="handleSort(1)">产教融合教学成果</span>
                <span class="search-box" :class="{ select: queryData.Type == 2 ? true : false }" @click="handleSort(2)">校区合作成果</span>
            </div>
            <div class="Detail">
                <CardHeader :title="'成果展示'" :pt="0" :pb="24" />
                <div class="itemLists" v-loading="loading">
                    <ItemListCard v-if="AchievementList.length > 0" :Path="'achievement/detail'" :data="AchievementList" type="calendar"></ItemListCard>
                    <div class="noData" v-else>~暂无数据~</div>
                </div>
            </div>
        </div>
        <AnswerTop />
    </div>
</template>

<script>
    import AnswerTop from "@/components/AnswerTop";
    import ItemListCard from "@/components/Common/ItemListCard.vue";
    import CardHeader from "@/components/Common/CardHeader.vue";
    import { dateTimeFilter } from "@/filters";
    import { getPageList } from "@/api/achievement";
    export default {
        name: "achievement",
        props: {},
        components: {
            CardHeader,
            ItemListCard,
            AnswerTop,
        },
        data() {
            return {
                loading: false,
                AchievementList: [],
                queryData: {
                    Type: 0,
                    keyword: "",
                    pageIndex: 1,
                    pageSize: 10,
                },
                Resume: {},
            };
        },
        computed: {
            formartDate() {
                return (data, formate) => dateTimeFilter(data, formate);
            },
        },
        watch: {},
        methods: {
            async getAchievementPageList() {
                this.loading = true;
                try {
                    const { Result } = await getPageList(this.queryData);
                    this.AchievementList = Result.Data;
                    this.loading = false;
                } catch (error) {
                    console.log(error);
                }
            },
            handleSort(sort) {
                this.queryData.Type = sort;
                this.getAchievementPageList();
            },
        },
        created() {
            this.getAchievementPageList();
        },
        mounted() {},
    };
</script>

<style scoped lang="scss">
    .achievement {
        width: 100%;
        min-height: calc(100vh - 202px);

        // background: #f5f5f5;
        .header {
            width: 100%;

            .service-banner {
                display: flex;
                width: 100%;
                height: 160px;
                background-color: #d3dfe8;
                background-image: url("~@/assets/images/banner/information.png");
                justify-content: center;
                align-items: center;

                .news-tips {
                    width: 1100px;
                    font-size: 32px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #ffffff;
                    line-height: 45px;

                    &-en {
                        font-size: 20px;
                        font-family: HelveticaNeue;
                        color: rgba(255, 255, 255, 0.35);
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .itemBox {
        box-sizing: border-box;
        width: 1100px;
        margin: 0 auto;
        padding-bottom: 40px;

        .breadcrumb {
            box-sizing: border-box;
            width: 100%;
            height: 46px;
            padding: 16px 0 8px;

            .search-box {
                transition: all 0.1s;
                user-select: none;
                cursor: pointer;
                margin-right: 32px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 16px;

                &:hover {
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #557df7;
                    line-height: 20px;
                }
            }

            .select {
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #557df7;
                line-height: 20px;
            }
        }

        .Detail {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            // padding: 32px;
            padding-top: 8px;
            min-height: 400px;
            background: #ffffff;
            border-radius: 8px;
            overflow: hidden;

            .itemLists {
                min-height: 70px;
            }
        }
    }

    .noData {
        width: 100%;
        height: 200px;
        line-height: 200px;
        text-align: center;
        color: #557df7;
    }
</style>